/* eslint-disable camelcase */
const BASE_URL = 'https://github.com/irusanov/ZenTimings';
const LATEST_RELEASE_URL = BASE_URL + '/releases/latest';
const API_URL = 'https://api.github.com/repos/irusanov/ZenTimings';

const scroll = (header, offset) => {
  if (document.body.scrollTop > offset || document.documentElement.scrollTop > offset) {
    if (!header.classList.contains('header-scrolled')) {
      header.classList.add('header-scrolled');
      header.classList.add('shadow');
    }
  } else if (header.classList.contains('header-scrolled')) {
    header.classList.remove('header-scrolled');
    header.classList.remove('shadow');
  }
};

const toggleMenu = (target) => {
  if (target) {
    const header = document.querySelector('#header');
    if (target.classList.contains('show')) {
      target.classList.remove('show');
      header.classList.remove('header-expanded');
    } else {
      target.classList.add('show');
      header.classList.add('header-expanded');
    }
  }
};

const makeId = (len) => {
  let textArray = [];
  const upperLimit = len > 1 && len < 100 ? len : 5; // protect the range
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (var i = 0; i < upperLimit; i += 1) {
    textArray.push(possible.charAt(Math.floor(Math.random() * possible.length)));
  }
  return textArray.join('');
};

class Alert {
  constructor(data = {}) {
    const { type = 'info', message = '' } = data;

    this.id = makeId(20);
    this.type = type;
    this.message = message;
    this.alertTimeout = 10000; // in milliseconds
    this.animationDuration = 250; // in milliseconds
    this.button = null;
  }

  getIcon() {
    return {
      'success': 'check_circle',
      'info': 'info',
      'warning': 'warning',
      'danger': 'error'
    }[this.type];
  }

  //<p><i class="material-icons">${icon}</i> ${data.message}</p>
  getHtml() {
    const icon = this.getIcon();
    const template = (data) => `
      <div id="${data.id}" class="alert alert-dismissible global fadein alert-${data.type}" role="alert" aria-live="assertive>
        <p><i class="material-icons"></i> ${data.message}</p>
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    `;

    return template(this);
  }

  handleCloseButtonClick = () => {
    this.hide();
  }

  show() {
    const body = document.querySelector('body');
    const temp = document.createElement('div');
    temp.innerHTML = this.getHtml();
    const alert = body.appendChild(temp.children[0]);

    alert.classList.add('fadein');
    temp.remove();

    this.button = alert.querySelector('button');
    this.button && this.button.addEventListener('click', this.handleCloseButtonClick);

    const timeout = setTimeout(() => {
      this.hide();
      clearTimeout(timeout);
    }, this.alertTimeout);
  }

  hide(id) {
    const alertId = id || this.id;
    const alert = document.getElementById(alertId);

    if (alert) {
      alert.classList.remove('fadein');
      alert.classList.add('fadeout');
      setTimeout(() => {
        this.button && this.button.removeEventListener('click', this.handleCloseButtonClick);
        alert.remove();
      }, this.animationDuration);
    }
  }
}

// Global alert
const alert = (() => {
  const alertType = {
    success: 'success',
    warning: 'warning',
    error: 'danger',
    info: 'info'
  };

  const show = data => new Alert(data).show();

  const success = msg => show({ type: alertType.success, message: msg });
  const error = msg => show({ type: alertType.error, message: msg });
  const warn = msg => show({ type: alertType.warning, message: msg });
  const info = msg => show({ type: alertType.info, message: msg });

  return {
    show,
    success,
    error,
    warn,
    info
  };
})();

// Dropdown
class Dropdown {
  constructor(target) {
    debugger;
    this.target = target;
    this.toggle = this.target.querySelector('.dropdown-toggle');
    this.menu = this.target.querySelector('.dropdown-menu');
    this.items = 0;
    this.attachEvents();
  }

  repositionDropdownMenu = () => {
    const rect = this.toggle.getBoundingClientRect();
    const menuRect = this.menu.getBoundingClientRect();

    const isOverflowingBottom = (rect.bottom + menuRect.height) > window.innerHeight;

    if (isOverflowingBottom) {
      this.menu.style.top = `-${menuRect.height}px`;
    } else {
      this.menu.style.top = `${rect.height}px`;
    }
  };

  toggleMenu = () => {
    this.menu.classList.toggle('show');
    if (this.menu.classList.contains('show')) {
      this.repositionDropdownMenu();
    }
  }

  attachEvents() {
    this.toggle.addEventListener('click', this.toggleMenu);

    document.addEventListener('click', (event) => {
      if (!this.toggle.contains(event.target)/* && !this.menu.contains(event.target)*/) {
        this.menu.classList.remove('show');
      }
    });
  }

  handleItemClick = (e) => {
    e.preventDefault();
    const text = e.target.textContent;
    this.toggle.textContent = text;

    const cards = document.querySelectorAll('#changelog-accordion .card');
    const { index } = e.target.dataset;
    const contentCard = cards[index];
    const card = document.querySelector('#changelog-card');

    if (contentCard) {
      const date = contentCard.querySelector('.card-header small').textContent;
      const changelog = contentCard.querySelector('.card-body').innerHTML;
      card.querySelector('.card-header').innerHTML = `v${text} <small class="text-muted small pl-2">${date}</small>`;
      card.querySelector('.card-body').innerHTML = changelog;
    } else {
      card.querySelector('.card-header').innerHTML = `v${text}`;
      card.querySelector('.card-body').innerHTML = `
        <div class="alert alert-warning" role="alert">
          No changelog available
        </div>
      `;
    }

    this.setActiveItem(index);
  }

  addItems(items = []) {
    items.forEach(item => {
      const { text, type } = item;
      if (type === 'divider') {
        this.addDivider();
      } else {
        this.addMenuItem(text);
      }
    });
  }

  addMenuItem(text) {
    const temp = document.createElement('template');
    temp.innerHTML = `<a class="dropdown-item" href="#p" data-content="#" data-index="${this.items++}">${text}</a>`;
    this.menu.appendChild(temp.content.cloneNode(true));
    const el = this.menu.querySelector('.dropdown-item:last-child');
    el && el.addEventListener('click', this.handleItemClick);
    temp.remove();

    if (this.items === 1) {
      el.click();
    }
  }

  addDivider() {
    const temp = document.createElement('div');
    temp.classList.add('dropdown-divider');
    this.menu.appendChild(temp.content.cloneNode(true));
    temp.remove();
  }

  setActiveItem(itemIndex = 0) {
    const items = this.menu.querySelectorAll('.dropdown-item');
    items.forEach((item) => {
      const { index } = item.dataset;
      if (index === itemIndex) {
        !item.classList.contains('active') && item.classList.add('active');
      } else if (item.classList.contains('active')) {
        item.classList.remove('active');
      }
    });
  }
}

// Dialog
class Dialog {
  constructor(target) {
    const { title = 'Dialog', content = null } = target.dataset;

    this.target = target;
    this.id = makeId(20);
    this.title = title;
    this.content = content;
    this.animationDuration = 250; // in milliseconds
    this.buttons = null;
    this.extraClass = "";

    if (content) {
      const el = document.querySelector(content);
      if (el) {
        this.extraClass = el.getAttribute('class').replace('d-none', "").trim();
      }
    }
  }

  handleKeyPress(key) {
    if (key === 'Escape') {
      this.close();
    }
  }

  handleCloseButtonClick = () => {
    this.close();
  }

  getHtml() {
    const template = (data) => `
      <div id="${data.id}" class="modal-overlay" role="dialog" aria-modal="true">
        <div class="dialog" role="document">
          <header>
            <h5>
              <span>${data.title}</span>
              <button type="button" class="closeBtn close" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </h2>
          </header>
          <div class="dialog-content">
            <!-- content goes here -->
          </div>
          <div class="toolbar">
            <button type="button" class="closeBtn btn btn-primary" id="${data.id}CloseBtn">
              <span>Close</span>
            </button>
          </div>
        </div>
      </div>
    `;

    return template(this);
  }

  open() {
    const body = document.querySelector('body');
    const temp = document.createElement('div');
    temp.innerHTML = this.getHtml();
    const dialog = body.appendChild(temp.children[0]);
    temp.remove();

    const dlgContentContainer = dialog.querySelector('.dialog-content');
    const dlgContent = document.querySelector(`${this.content}`);

    dlgContent && dlgContentContainer && (dlgContentContainer.innerHTML = dlgContent.innerHTML);

    document.addEventListener('keydown', ({key}) => {
      this.handleKeyPress(key);
    });

    this.buttons = dialog.querySelectorAll('button.closeBtn');
    this.buttons.forEach(button => {
      button.addEventListener('click', this.handleCloseButtonClick);
    });

    body.setAttribute('aria-busy', true);

    // dialog.classList.remove('fadeout');
    dialog.classList.add('fadein');
    if (this.extraClass) {
      dialog.querySelector('.dialog').classList.add(this.extraClass);
    }

    setTimeout(() => {
      const buttonsCount = this.buttons?.length || 0;
      if (buttonsCount > 0) {
        this.buttons[buttonsCount - 1].focus();
      }
    });
  }

  close() {
    const dialog = document.getElementById(this.id);

    if (dialog) {
      const body = document.querySelector('body');
      dialog.classList.remove('fadein');
      dialog.classList.add('fadeout');
      setTimeout(() => {
        body.setAttribute('aria-busy', false);
        document.removeEventListener('keydown', this.handleKeyPress);
        if (this.buttons && this.buttons.length > 0) {
          this.buttons.forEach(button => button.removeEventListener('click', this.handleCloseButtonClick));
        }
        dialog.remove();
        this.target.focus();
      }, this.animationDuration);
    }
  }
}

const modal = (() => {
  return {
    open: el => {
      const dialog = new Dialog(el);
      return dialog.open();
    }
  };
})();

const modalOpenHandler = (event) => {
  const existing = document.querySelectorAll('.modal-overlay').length > 0;

  if (existing) return false;

  const el = event.currentTarget;
  return el && modal.open(el);
};

const toggleAccordionBlock = (event) => {
  const el = event.currentTarget;
  const content = el.nextElementSibling;

  content.classList.toggle('show');
  content.classList.toggle('hide');
  el.setAttribute('aria-expanded',
    !(String(el.getAttribute('aria-expanded')).toLowerCase() === 'true'));
};

// IE NodeList.forEach
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

document.addEventListener('DOMContentLoaded', () => {
  const oauthToken = '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';
  const header = document.querySelector('#header');
  const main = document.querySelector('main section:first-child > .container');
  const offset = main.offsetTop - header.offsetHeight;

  // Transform header on page scroll
  window.addEventListener('scroll', () => {
    window.requestAnimationFrame(() => scroll(header, offset));
  });

  // Toggle menu
  const menuBtn = document.querySelectorAll('.navbar-toggler');
  menuBtn.forEach(btn => {
    const selector = btn.getAttribute('data-target');
    const target = document.querySelector(selector);
    btn.addEventListener('click', () => toggleMenu(target));
  });

  // Submit PayPal donate form
  const donateBtn = document.querySelector('#donateBtn');
  donateBtn && donateBtn.addEventListener('click', () => {
    const paypalForm = document.querySelector('#donateForm');
    paypalForm.submit();
  });

  // Close hamburger menu on item click
  const navLinks = document.querySelectorAll('nav a');
  const mainNav = document.querySelector('#mainNav');
  navLinks.forEach(link => {
    link.addEventListener('click', () => {
      if (mainNav.classList.contains('show')) toggleMenu(mainNav);
    });
  });

  const dialogLinks = document.querySelectorAll('a[data-content], button[data-content]');
  dialogLinks.forEach(link => {
    link.addEventListener('click', modalOpenHandler.bind(this), false);
  });

  const accordionHeaders = document.querySelectorAll('.accordion .card .card-header');
  accordionHeaders.forEach(h => {
    h.addEventListener('click', toggleAccordionBlock.bind(this), false);
  });

  const parseDate = (s) => {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const date = new Date(s);
    const m = months[date.getMonth()];
    const d = date.getDate().toString().padStart(2, 0);
    const y = date.getFullYear();

    return `${m} ${d} ${y}`;
  };

  const fetchReleases = async () => {
    const url = `${API_URL}/releases`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `token ${oauthToken}`
      }
    });

    return await response.json();
  };

  const changelogDropdown = new Dropdown(document.querySelector('#changelog-dropdown'));

  // Fetch latest version
  const fillInReleases = (obj) => {
    const downloadDialogContent = document.querySelector('#download-dialog table tbody');
    const releases = [...obj];
    // releases.shift();

    releases.forEach((release, index) => {
      const temp = document.createElement('template');
      const date = parseDate(`${release.assets[0].created_at}`);
      const versionString = `${release.tag_name.substring(1)}${index === 0 ? " (latest)" : ""}`;
      temp.innerHTML = `
        <tr id="${release.id}">
          <td class="align-middle">${versionString}</td>
          <td class="align-middle text-center">${date}</td>
          <td class="align-middle">${Math.round(release.assets[0].size / 1024)} KB</td>
          <td class="align-middle text-center">${release.assets[0].download_count}</td>
          <td><a class="btn btn-primary btn-sm" href="${release.assets[0].browser_download_url}">Download</a></td>
        </tr>
      `;
      downloadDialogContent.appendChild(temp.content.cloneNode(true));
      changelogDropdown.addMenuItem(`${versionString}`);
      temp.remove();
    });
  };

  const getCards = () => {
    const arr = [];
    const cards = document.querySelectorAll('.parallax .download-card');

    cards.forEach(card => {
      const downloadBtn = card.querySelector('button');
      const versionElements = card.querySelectorAll('.version-info > div');
      arr.push({
        el: card,
        version: versionElements[0],
        loading: versionElements[1],
        size: versionElements[2],
        md5: versionElements[3],
        downloads: versionElements[4],
        button: downloadBtn
      });
    });

    return arr;
  };

  const cards = getCards();

  fetchReleases()
    .then(data => {
      const latest = data[0];
      const assets = latest.assets;
      const totalDownloads = data.reduce((acc, curr) => {
        const { download_count } = curr.assets[0];
        acc += +download_count;
        return acc;
      }, 0);

      fillInReleases(data);

      assets.forEach((asset, index) => {
        const card = cards[index];
        const { name, size, download_count, browser_download_url, created_at } = asset;
        const version = name.replace('ZenTimings_', '').replace('.zip', '');
        const date = parseDate(created_at);

        if (card && browser_download_url) {
          //const buttonTextElement = card.button.querySelector("span");
          //buttonTextElement.textContent = `${buttonTextElement.textContent} ${version}`;
          card.button.addEventListener('click', () => {
            // modal.open({
            //   dataset: {
            //     title: 'Logo has changed!',
            //     content: '#notice-dialog',
            //   },
            // });
            window.location = browser_download_url;
          });
        }

        card.version.textContent = `Latest: ${version} for Windows (${date})`;
        card.version.classList.remove('d-none');
        card.size.textContent = `Size: ${Math.round(size / 1024)} KB`;
        card.downloads.textContent = `Downloads: ${download_count} (${totalDownloads} total)`;
        card.loading.remove();
      });

      // alert.success('Success');
    })
    .catch(err => {
      cards.forEach(card => {
        card.size.classList.add('d-none');
        card.downloads.classList.add('d-none');
        card.button.addEventListener('click', () => { window.location = LATEST_RELEASE_URL; });
        card.loading.remove();
      });
      alert.error('Failed to get latest version. Please try again later.');
      console.error(err);
    })
    .then(() => {
      const downloadOlderBtn = document.getElementById('downloadOlder');
      downloadOlderBtn && downloadOlderBtn.removeAttribute('disabled');
      cards.forEach(card => {
        card.button.removeAttribute('disabled');
      });
    });
});
